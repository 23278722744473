import React, { useState } from "react";
import UbiEnergyVideo from "../assets/videos/UbiEnergyVideo.mp4";
import UbitrayHareketi from "../assets/videos/UbitrayHareketi.mp4";
import Isik from "../assets/videos/Isik.mp4";
import { Typography, Grid, Divider } from "@mui/material";

export default function ProductDetailVideos() {
  const [videoStates, setVideoStates] = useState({
    isik: true,
    ubitrayHareketi: true,
    ubiEnergyVideo: true,
  });

  const handleVideoClick = (event, videoKey) => {
    const video = event.currentTarget;
    if (video.paused) {
      video.play();
      setVideoStates({ ...videoStates, [videoKey]: false });
    } else {
      video.pause();
      setVideoStates({ ...videoStates, [videoKey]: true });
    }
  };

  const handleVideoEnd = (videoKey) => {
    setVideoStates({ ...videoStates, [videoKey]: true });
  };

  const renderVideoWithOverlay = (videoKey, src) => (
    <div style={{ position: "relative", width: "100%", maxWidth: 600 }}>
      <video
        width="100%"
        style={{ borderRadius: 20, display: "block" }}
        onClick={(e) => handleVideoClick(e, videoKey)}
        onEnded={() => handleVideoEnd(videoKey)}
        id={`video-${videoKey}`} // Added ID to reference the video
      >
        <source src={src} type="video/mp4" />
      </video>
      {videoStates[videoKey] && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 2,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "50%",
            padding: 20,
            cursor: "pointer",
          }}
          onClick={() => {
            const video = document.getElementById(`video-${videoKey}`);
            video.play(); // Play the video
            setVideoStates({ ...videoStates, [videoKey]: false });
          }}
        >
          <span
            style={{
              fontSize: 32,
              color: "white",
              fontWeight: "bold",
              userSelect: "none",
            }}
          >
            ▶
          </span>
        </div>
      )}
    </div>
  );

  let width = window.innerWidth;
  if (width > 800) {
    return (
      <Grid
        container
        justifyContent="space-evenly"
        alignItems="center"
        direction="row"
        style={{
          backgroundColor: "#FBFBFD",
          borderRadius: 10,
          padding: 20,
          paddingTop: 60,
          paddingBottom: 100,
          maxWidth: 1300,
        }}
      >
        <Grid item xs={6}>
          {renderVideoWithOverlay("isik", Isik)}
        </Grid>
        <Grid item xs={6} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              Özel LED ışıkları
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              Kullandığımız LED ışıklar Ubicro için özel üretilmiş ışıklardır.
              Güneşe benzer ışıklar hem gözü yormaz hemde bitkilerin büyümesi
              için optimum ışık kaynağıdır
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ width: "100%", marginTop: 100, marginBottom: 100 }} />

        <Grid item xs={6} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              UbiTray
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              Seçtiğiniz bitkiyi kolayca yetiştirebilirsiniz. UbiTray’deki
              bitkiler, genetiği değiştirilmemiş (GDO’suz) tohumlardan
              gelmektedir. Tek yapmanız gereken, cihaza UbiTray’i yerleştirmek.
              Cihaz, seçtiğiniz bitkiyi otomatik algılar, ve buna göre sistemi
              çalıştırır.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {renderVideoWithOverlay("ubitrayHareketi", UbitrayHareketi)}
        </Grid>

        <Divider style={{ width: "100%", marginTop: 100, marginBottom: 100 }} />

        <Grid item xs={6}>
          {renderVideoWithOverlay("ubiEnergyVideo", UbiEnergyVideo)}
        </Grid>
        <Grid item xs={6} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              UbiEnergy
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              UbiEnergy ile bitkinin ihtiyaç duyduğu tüm besin ve pH dengesi
              mevcuttur.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{
          backgroundColor: "#FBFBFD",
          borderRadius: 10,
          padding: 10,
          paddingTop: 30,
          paddingBottom: 50,
        }}
      >
        <Grid item xs={12}>
          {renderVideoWithOverlay("isik", Isik)}
        </Grid>
        <Grid item xs={12} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              Özel LED ışıkları
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              Kullandığımız LED ışıklar Ubicro için özel üretilmiş ışıklardır.
              Güneşe benzer ışıklar hem gözü yormaz hemde bitkilerin büyümesi
              için optimum ışık kaynağıdır
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ width: "100%", marginTop: 100, marginBottom: 100 }} />

        <Grid item xs={12}>
          {renderVideoWithOverlay("ubitrayHareketi", UbitrayHareketi)}
        </Grid>
        <Grid item xs={12} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              UbiTray
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              Seçtiğiniz bitkiyi kolayca yetiştirebilirsiniz. UbiTray’deki
              bitkiler, genetiği değiştirilmemiş (GDO’suz) tohumlardan
              gelmektedir. Tek yapmanız gereken, cihaza UbiTray’i yerleştirmek.
              Cihaz, seçtiğiniz bitkiyi otomatik algılar, ve buna göre sistemi
              çalıştırır.
            </Typography>
          </Grid>
        </Grid>

        <Divider style={{ width: "100%", marginTop: 100, marginBottom: 100 }} />

        <Grid item xs={12}>
          {renderVideoWithOverlay("ubiEnergyVideo", UbiEnergyVideo)}
        </Grid>
        <Grid item xs={12} style={{ padding: 30 }}>
          <Grid container justifyContent="center" alignItems="center">
            <Typography variant="h4" gutterBottom style={{ color: "#4F4F5F" }}>
              UbiEnergy
            </Typography>
            <Typography variant="h6" style={{ color: "#B4B4C7" }}>
              UbiEnergy ile bitkinin ihtiyaç duyduğu tüm besin ve pH dengesi
              mevcuttur.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}
